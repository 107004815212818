import React from 'react';
import cn from 'classnames';
import HeaderLinkItem from '@/finsight/ui/common/components/header/Header/HeaderLinkItem';
import { IProfileItem } from '@/finsight/ui/common/components/header/Header/MarketDataMenu/Content/types';
import {
  useHeaderDataActiveMenuContext,
} from '@/finsight/ui/common/components/header/Header/HeaderMenuActiveDataContext';

import headerStyles from '@/finsight/ui/common/components/header/Header/header.scss';
import styles from './profile.scss';

interface IProfileItemProps {
  item: IProfileItem,
  name: string,
  hasDeals: boolean,
  className: string,
  activeItemId: string,
  isExpanded: boolean,
  isChildProfile?: boolean,
  onProfileClick: ({ tool_name, profile_group }: { tool_name: string, profile_group: string }) => void,
  toggleIsExpanded: (x: boolean) => void,
}

const ProfileItem = ({
  item,
  name,
  hasDeals,
  className,
  isChildProfile,
  activeItemId,
  isExpanded,
  onProfileClick,
  toggleIsExpanded,
}: IProfileItemProps) => {
  const {
    activeProduct,
    activeRegion,
  } = useHeaderDataActiveMenuContext();
  const profile = (
    <div className={ cn(className, { [styles.disabledProfile]: !hasDeals }) }>
      { item.color && (
        <div
          className={ styles.sectorIndicator }
          style={ { backgroundColor: item.color } }
        />
      ) }
      <span>{ item.name }</span>
      { item.beta && <span className={ headerStyles.beta }>(Beta)</span> }
    </div>
  );

  return (
    <div
      key={ item.id }
      className={ styles.profileWrp }
    >
      { hasDeals
        ? (
          <HeaderLinkItem
            key={ item.id }
            isActive={ activeItemId === item.id }
            activeClassName={ styles.active }
            linkTo={ {
              pathname: `/${ item.link }`,
              search: `products=${ activeProduct.abbreviation }&regions=${ activeRegion.abbreviation }`,
            } }
            onClick={ () => onProfileClick({ tool_name: item.name, profile_group: name }) }
            toggleIsExpanded={ toggleIsExpanded }
            isExpanded={ isChildProfile ? false : isExpanded }
            isWithExpand={ !isChildProfile }
          >
            { profile }
          </HeaderLinkItem>
        )
        : profile }
    </div>
  );
};

export default ProfileItem;
