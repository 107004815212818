import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import HeaderRepository from '@/finsight/infrastructure/repository/HeaderRepository';
import { NotificationManager } from '@/ui/shared/components/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import {
  IDictionariesState,
  initDictionaries,
  prepareDictionariesData,
} from '@/finsight/application/Dictionaries/helpers';

const useDictionaries = (serverDictionaries: IDictionariesState): IDictionariesState => {
  const { container } = useDIContext();

  const [state, setState] = useState<IDictionariesState>(serverDictionaries || initDictionaries);

  const setGlobalState = (dictionaries) => {
    setState(prepareDictionariesData(dictionaries));
  };

  const fetchFEDictionaries = async () => {
    const reqService = container.get(HeaderRepository);
    try {
      const frontendDictionaries = await reqService.getDictionaries();
      setGlobalState(frontendDictionaries);
    } catch (e) {
      NotificationManager.error(getErrorMessage(e));
    }
  };

  useEffect(() => {
    if (state.isHeaderInitialized) {
      return;
    }
    if (!serverDictionaries || serverDictionaries.hasServerError) {
      fetchFEDictionaries();
    }
  }, []);
  return {
    ...state,
  };
};

type DictionariesContextType = ReturnType<typeof useDictionaries>;

export const DictionariesContext = createContext<DictionariesContextType>(null);

export const useDictionariesContext = () => {
  const context = useContext(DictionariesContext);
  if (!context) {
    throw new Error('useDictionariesContext must be used within the DictionariesContext');
  }
  return context;
};

interface IProps {
  dictionaries?: IDictionariesState,
  children: React.ReactNode,
}

const DictionariesContextProvider = ({ children, dictionaries }: IProps) => (
  <DictionariesContext.Provider value={ useDictionaries(dictionaries) }>
    { children }
  </DictionariesContext.Provider>
);

export default DictionariesContextProvider;
