import React from 'react';

import staticHeaderStyles from './staticHeader.scss';
import logoWings from '@/ui/shared/assets/logo/logoWings.inline.svg';

interface IProps {
  title: string,
  tagLine: string,
  children?: React.ReactNode,
  headerBackgroundComponent?: React.FC,
}

const StaticHeader = (props: IProps) => (
  <div className={ staticHeaderStyles.header }>
    <div className={ staticHeaderStyles.content }>
      <div className={ staticHeaderStyles.contentWrapper }>
        <div>
          <h1 className={ staticHeaderStyles.title }>{ props.title }</h1>
          { props.tagLine && (
            <div className={ staticHeaderStyles.tagLine }>{ props.tagLine }</div>
          ) }
        </div>
        { props.children }
      </div>
    </div>
    <div className={ staticHeaderStyles.backgroundWrapper }>
      { props.headerBackgroundComponent
        ? React.createElement(props.headerBackgroundComponent)
        : (
          <i
            className={ staticHeaderStyles.backgroundLogo }
            dangerouslySetInnerHTML={ { __html: logoWings } }
          />
        ) }
    </div>
  </div>
);

export default StaticHeader;
