import React, { FC } from 'react';
import cn from 'classnames';
import { Icon, IconType } from '@dealroadshow/uikit';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import { MARKET_DATA_MENU_BUTTON_ID } from '@/finsight/ui/common/components/header/Header/constants';
import { useHeaderMenuContext } from '@/finsight/ui/common/components/header/Header/HeaderMenuContext';

import styles from './marketDataMenu.scss';

interface IMarketDataMenuButtonProps {
  buttonClassName: string,
}

const MarketDataMenuButton: FC<IMarketDataMenuButtonProps> = ({ buttonClassName }) => {
  const {
    isMobileMenuOpen,
    isMarketDataMenuOpen,
    setIsMarketDataMenuOpen,
  } = useHeaderMenuContext();

  const toggleMarketDataMenu = () => {
    // @ts-ignore
    setIsMarketDataMenuOpen(!isMarketDataMenuOpen);
  };

  return (
    <Button
      variant={ variantTypes.text }
      className={ cn(styles.marketDataButton, buttonClassName, {
        [styles.isMobileMenuHidden]: !isMobileMenuOpen,
        [styles.isActive]: isMarketDataMenuOpen,
      }) }
      // @ts-ignore
      id={ MARKET_DATA_MENU_BUTTON_ID }
      dataTest="marketDataMenuButton"
      onClick={ toggleMarketDataMenu }
    >
      <div className={ styles.iconContainer }>
        <Icon type={ IconType.analytics } />
        <span>Market Data</span>
      </div>
      <i className={ cn(styles.toggleIcon, { [styles.open]: isMarketDataMenuOpen }) } />
    </Button>
  );
};

export default MarketDataMenuButton;
