import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';
import drsUrl from '@/dealroadshow/infrastructure/url/drsUrl';
import evercallUrl from '@/evercall/infrastructure/evercallUrl';
import dataroomUrl from '@/dataroom/infrastructure/dataroomUrl';
import URL from '@/finsight/Router/urlConstants';
import config from '@/Framework/config';
import { IFooter } from '@/finsight/domain/vo/IFooter';

import styles from '@/finsight/ui/common/components/Footer/footer.scss';

const classNames = {
  columnTitleClassName: styles.columnTitle,
  panelClassName: styles.panel,
  arrowClassName: styles.panelArrow,
  columnClassName: styles.column,
};

export const footerCollection: IFooter = {
  navigation: [
    {
      path: finsightWebUrl.getComplianceUrl(),
      title: 'Compliance',
      shouldOpenInNewTab: true,
    },
    {
      path: finsightWebUrl.getPrivacyPolicyUrl(),
      title: 'Privacy',
      shouldOpenInNewTab: true,
    },
    {
      path: finsightWebUrl.getSecurityPolicyUrl(),
      title: 'Security',
      shouldOpenInNewTab: true,
    },
    {
      path: finsightWebUrl.getTermsOfUseUrl(),
      title: 'Terms',
      shouldOpenInNewTab: true,
    },
  ],
  content: [
    {
      key: 'company',
      title: 'Company',
      ...classNames,
      collection: [
        { title: 'About', path: URL.ABOUT },
        { title: 'Careers', path: URL.CAREERS },
        { title: 'Contact', path: URL.CONTACT },
        { title: 'Solutions', path: URL.SOLUTIONS },
      ],
    },
    {
      key: 'finsight',
      title: 'Finsight.com',
      ...classNames,
      collection: [
        { title: 'Features', path: URL.FEATURES },
        { title: 'Global Issuers List', path: URL.ISSUERS_LIST },
        { title: 'Global Parents List', path: URL.SPONSORS_LIST },
        { title: 'Pricing', path: URL.PRICING },
      ],
    },
    {
      key: 'products',
      title: 'Products',
      ...classNames,
      collection: [
        {
          title: 'CapEdge',
          // @todo: move this to config and create separate tenant in docker and MG
          path: 'https://capedge.com',
          shouldOpenInNewTab: true,
        },
        {
          title: 'Deal Roadshow',
          path: drsUrl.getUrl(),
          shouldOpenInNewTab: true,
        },
        {
          title: 'DealVDR',
          path: dataroomUrl(config.tenant.tenantDealvdr.code).getUrl(),
          shouldOpenInNewTab: true,
        },
        {
          title: 'Evercall',
          path: evercallUrl.getUrl(),
          shouldOpenInNewTab: true,
        },
        {
          title: 'Finsight.com',
          path: finsightWebUrl.getUrl(),
          shouldOpenInNewTab: true,
        },
        {
          title: 'More',
          path: finsightWebUrl.getOurProductsUrl(),
          shouldOpenInNewTab: true,
        },
      ],
    },
    {
      key: 'sales',
      title: 'Sales & Support',
      ...classNames,
      collection: [
        { title: 'Americas', phoneNumber: '+1 888 997 6610 ' },
        { title: 'APAC', phoneNumber: '+852 3018 1600' },
        { title: 'EMEA', phoneNumber: '+44 80817 87364' },
        {
          title: `${ config.tenant.finsight.supportEmail }`,
          path: `mailto:${ config.tenant.finsight.supportEmail }?subject=Finsight Sales %26 Support Request`,
          linkClassName: styles.supportEmailLink,
        },
      ],
    },
  ],
};
