import React from 'react';
import { useDispatch } from 'react-redux';
import { FinsightAvatar } from '@/ui/shared/components/User/ProfileWidget/Avatar';
import Menu from '@/ui/shared/components/User/ProfileWidget/Menu';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import { logout } from '@/finsight/application/actions/header/actions';
import CheckUserPlanAccess from '@/finsight/ui/common/components/CheckUserPlanAccess';
import { UserPlanType } from '@/users/domain/UserPlan';
import UserProfileFallback from '@/finsight/ui/common/components/header/Header/UserProfile/UserProfileFallback';

import userProfileStyles from './userProfile.scss';

const UserProfileMenu = () => {
  const { currentUser } = useSessionContext();
  const dispatch = useDispatch();

  const onLogout = (event) => {
    event.preventDefault();
    dispatch(logout());
  };

  return (
    <div className={ userProfileStyles.menuWrapper }>
      <Menu
        logoutAction={ onLogout }
        placement="bottom-end"
      >
        <CheckUserPlanAccess
          requiredUserPlan={ UserPlanType.STARTER }
          fallback={ (
            <UserProfileFallback />
          ) }
        >
          <FinsightAvatar
            email={ currentUser.email }
            firstName={ currentUser.firstName }
          />
        </CheckUserPlanAccess>
      </Menu>
    </div>
  );
};

export default UserProfileMenu;
