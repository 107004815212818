import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import hInteraction from '@/ui/shared/styles/helpers/interaction.scss';
import { isScreenS } from '@/ui/shared/styles/screen/screen';

const defaultValue = {
  isMobileMenuOpen: false,
  isMarketDataMenuOpen: false,
  isSearchResultsOpen: false,
  isActiveEquities: false,
  isUserProfileMenuOpen: false,
  setIsMobileMenuOpen: (trigger) => trigger,
  setIsMarketDataMenuOpen: (trigger) => trigger,
  setIsSearchResultsOpen: () => {},
  setIsActiveEquities: (trigger) => trigger,
  setIsUserProfileMenuOpen: (trigger) => trigger,
};

export const HeaderMenuContext = createContext(defaultValue);

/**
 * @return {HeaderMenuContext}
 */
export function useHeaderMenuContext() {
  const context = useContext(HeaderMenuContext);
  if (!context) {
    throw new Error('useHeaderMenuContext must be used within the HeaderMenuContext');
  }
  return context;
}

interface IProps {
  children: React.ReactNode,
}

const HeaderMenuContextProvider = ({ children }: IProps) => {
  const [isMobileSize, setIsMobileSize] = useState(isScreenS());
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMarketDataMenuOpen, setIsMarketDataMenuOpen] = useState(false);
  const [isSearchResultsOpen, setIsSearchResultsOpen] = useState(false);
  const [isActiveEquities, setIsActiveEquities] = useState(false);
  const [isUserProfileMenuOpen, setIsUserProfileMenuOpen] = useState(false);
  const headerMenuContextValue = {
    isMobileMenuOpen,
    setIsMobileMenuOpen,
    isMarketDataMenuOpen,
    isActiveEquities,
    setIsMarketDataMenuOpen,
    isSearchResultsOpen,
    setIsSearchResultsOpen,
    setIsActiveEquities,
    isUserProfileMenuOpen,
    setIsUserProfileMenuOpen,
  };

  const checkIsMobileWidth = useCallback(() => setIsMobileSize(isScreenS()), []);

  useEffect(() => {
    window.addEventListener('resize', checkIsMobileWidth);
    return () => {
      window.removeEventListener('resize', checkIsMobileWidth);
    };
  }, []);

  useEffect(() => {
    if (isMarketDataMenuOpen || isMobileMenuOpen || isSearchResultsOpen) {
      document.body.classList.add(hInteraction.nonScrollable);
    }
    return () => {
      document.body.classList.remove(hInteraction.nonScrollable);
    };
  }, [isMarketDataMenuOpen, isMobileMenuOpen, isSearchResultsOpen]);

  useEffect(() => {
    if (isMarketDataMenuOpen || isMobileMenuOpen) {
      setIsSearchResultsOpen(false);
    }
  }, [isMarketDataMenuOpen, isMobileMenuOpen]);

  useEffect(() => {
    if (isMobileSize && isMarketDataMenuOpen && !isMobileMenuOpen) {
      setIsMarketDataMenuOpen(false);
    }
  }, [isMobileMenuOpen]);

  useEffect(() => {
    if (isMobileSize && isMarketDataMenuOpen) {
      setIsMobileMenuOpen(true);
    }

    if (!isMobileSize && isMobileMenuOpen) {
      setIsMobileMenuOpen(false);
    }
  }, [isMobileSize]);

  useEffect(() => {
    if (isSearchResultsOpen) {
      setIsMobileMenuOpen(false);
      setIsMarketDataMenuOpen(false);
    }
  }, [isSearchResultsOpen]);

  return (
    // @ts-ignore
    <HeaderMenuContext.Provider value={ headerMenuContextValue }>{ children }</HeaderMenuContext.Provider>
  );
};

export default HeaderMenuContextProvider;
