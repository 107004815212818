import '@/Framework/browser/polyfill';
import { AppProps } from 'next/app';
import React, { useEffect } from 'react';
import { Store } from 'redux';
import { DefaultSeo } from 'next-seo';
import GlobalStyles, { styles } from '@/finsight/ui/common/GlobalStyles';
import { SEO } from '@/finsight/ui/common/components/Seo';
import ErrorBoundary from '@/Framework/ErrorHandling/ErrorBoundary';
import ApplicationFallback from '@/Framework/ErrorHandling/ErrorBoundary/ApplicationFallback';
import { ConnectedRouter } from '@dealroadshow/connected-next-router';
import Layout from '@/finsight/ui/common/components/Layout';
import Bootstrap, { wrapper } from '@/Framework/Bootstrap';
import Head from 'next/head';
import reducer from '../reducer';
import isServer from '@/Framework/Router/Next/isServer';
import { MemoryRouter } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import Container from '@/Framework/DI/Container';
import Favicon from '@/finsight/ui/common/components/Favicon';
import GlobalProps from '@/Framework/Router/Next/GlobalProps';
import { IGlobalProps } from '@/Framework/Router/Next/withGlobalProps';
import FinsightGlobalProps from '@/finsight/application/FinsightGlobalProps';
import { IDictionariesState } from '@/finsight/application/Dictionaries/helpers';
import { IUserPlanState } from '@/finsight/application/User/UserPlan/helpers';
import RouteServiceRepository from '@/finsight/infrastructure/repository/RouteServiceRepository';

interface IFinsightProps extends IGlobalProps {
  dictionaries?: IDictionariesState,
  userPlanState?: IUserPlanState,
}

const App = ({ Component, pageProps, container, store }: AppProps<IFinsightProps> & {
  container: Container,
  store?: Store,
}) => {
  useEffect(() => {
    RouteServiceRepository.getApiCookies();
  }, []);

  const Router = isServer() ? MemoryRouter : BrowserRouter;
  return (
    <GlobalStyles>
      <Favicon />
      <Head>
        <title />
        <meta charSet="utf-8" />
        <meta httpEquiv="Cache-Control" content="no-store, no-cache, must-revalidate, proxy-revalidate, max-age=0" />
        <meta httpEquiv="Pragma" content="no-store" />
        <meta httpEquiv="Expires" content="0" />
        <meta content="initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no" name="viewport" />
        <meta name="HandheldFriendly" content="true" />
      </Head>
      { /* @ts-ignore */ }
      <ErrorBoundary fallback={ ApplicationFallback }>
        { /* Just adding global styles to build here */ }
        <div className={ styles.body } />
        <Bootstrap
          session={ pageProps?.session }
          container={ container }
          store={ store }
          cookies={ pageProps?.cookies }
        >
          <Router>
            <ConnectedRouter>
              <GlobalProps { ...pageProps }>
                <FinsightGlobalProps
                  dictionaries={ pageProps?.dictionaries }
                  userPlanState={ pageProps?.userPlanState }
                >
                  <DefaultSeo { ...SEO } />
                  <Layout breadcrumbs={ pageProps.breadcrumbs }>
                    <Component { ...pageProps } />
                  </Layout>
                </FinsightGlobalProps>
              </GlobalProps>
            </ConnectedRouter>
          </Router>
        </Bootstrap>
      </ErrorBoundary>
    </GlobalStyles>
  );
};

export default wrapper(reducer).withRedux(App);
