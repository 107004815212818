import { matchPath } from 'react-router-dom';
import URL from '@/finsight/Router/urlConstants';
import { toSlug } from '@/Framework/url/helpers/toSlug';
import { formatDealName } from '@/finsight/application/actions/deals/helpers';
import { redirectMap } from './redirectMap';
import Region, { RegionAbbreviations } from '@/finsight/domain/Region';

import Product, { ProductAbbreviations } from '@/finsight/domain/Product';

/**
 * @param deal { Object }
 * @return {string}
 */
export const getDealRoute = (deal) => {
  const { viewId } = deal;
  return `${ URL.DEAL }-${ viewId }-${ toSlug(formatDealName(deal)) }`;
};

/**
 * @param company { Object }
 * @return {string}
 */
export const getParentRoute = (company) => `${ URL.SPONSOR }-${ company.viewId }-${ toSlug(company.name) }`;

/**
 * @param company { Object }
 * @return {string}
 */
export const getIssuerRoute = (company) => `${ URL.ISSUER }-${ company.viewId }-${ toSlug(company.name) }`;

/**
 * @param url { String }
 * @param company { Object }
 * @return {string}
 */
export const getCatalogRoute = (
  url,
  company,
) => `${ url }-${ company.viewId }-${ toSlug(company.name) }?products=${
    [
      ProductAbbreviations.ABS,
      ProductAbbreviations.HYC,
      ProductAbbreviations.IGC,
    ].join(',')
  }&regions=${
    [
      RegionAbbreviations.APAC,
      RegionAbbreviations.EMEA,
      RegionAbbreviations.LATM,
      RegionAbbreviations.USOA,
    ].join(',')
  }`;

/**
 * @param industry { String }
 * @param subindustry? { String }
 * @return {string}
 */
export const getIndustryRoute = (industry, subindustry = '') => {
  let convertedIndustry = toSlug(decodeURI(industry));
  if (subindustry) {
    convertedIndustry += `-${ toSlug(decodeURI(subindustry)) }`;
  }
  return `/${ convertedIndustry }-${ URL.INDUSTRY_SUFFIX }`;
};

/**
 * @param sector { String }
 * @param subsector? { String }
 * @return {string}
 */
export const getSectorRoute = (sector, subsector = '') => {
  let convertedSector = toSlug(decodeURI(sector));
  if (subsector) {
    convertedSector += `-${ toSlug(decodeURI(subsector)) }`;
  }
  return `/${ convertedSector }-${ URL.SECTOR_SUFFIX }`;
};

/**
 * @param path { String }
 * @param search { String }
 * @return {string}
 */
export const getNewRoute = (path, search = '') => {
  if (path.indexOf(URL.OLD_ROUTES.INDUSTRY) === 0) {
    const { params: { industry, subindustry } } = matchPath(path, `${ URL.OLD_ROUTES.INDUSTRY }/:industry/:subindustry?`);
    return getIndustryRoute(industry, subindustry);
  }
  if (path.indexOf(URL.OLD_ROUTES.SECTOR) === 0) {
    const { params: { sector, subsector } } = matchPath(path, `${ URL.OLD_ROUTES.SECTOR }/:sector/:subsector?`);
    return getSectorRoute(sector, subsector);
  }
  if (path === URL.OLD_ROUTES.MARKET_OVERVIEW_ALL) {
    const searchParams = new URLSearchParams(search);
    const products = searchParams.get('products');
    let regions = searchParams.get('regions');

    if (!products) {
      return `${ URL.MARKET_OVERVIEW_ALL }?products=${ Product.getDefault() }&regions=${ Region.getDefault() }`;
    }
    if (!regions) {
      searchParams.set('regions', Region.getDefault());
    }

    if (products.indexOf(ProductAbbreviations.ABS) > -1) {
      return `${ URL.MARKET_OVERVIEW_ALL }?${ searchParams.toString() }`;
    }
    if (products.indexOf(ProductAbbreviations.IGC) > -1) {
      return `${ URL.MARKET_OVERVIEW_IGC }?${ searchParams.toString() }`;
    }
    if (products.indexOf(ProductAbbreviations.HYC) > -1) {
      return `${ URL.MARKET_OVERVIEW_HYC }?${ searchParams.toString() }`;
    }
  }

  return redirectMap[path];
};
