import * as actionTypes from './actionTypes';

const initialState = {
  collection: [
    {
      id: '',
      name: 'Eastern Time',
      timeZone: 'America/New_York',
      abbr: 'ET',
      default: 1,
    },
  ],
  options: [],
  isCollectionFetching: false,
};

export default function timeZone(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_TIMEZONE:
      return {
        ...state,
        isCollectionFetching: true,
      };
    case actionTypes.REQUEST_TIMEZONE_SUCCESS:
      return {
        ...state,
        collection: action.payload,
        isCollectionFetching: false,
      };

    case actionTypes.INIT_TIMEZONE_OPTION:
      return {
        ...state,
        options: action.payload,
      };

    case actionTypes.REQUEST_TIMEZONE_ERROR:
      return {
        ...state,
        isCollectionFetching: false,
      };
    case actionTypes.RESET_TIMEZONE:
      return {
        initialState,
      };
    default:
      return state;
  }
}
