import React, { FC, useEffect, useState } from 'react';
import Head from 'next/head';
import cn from 'classnames';
import { useRouter } from 'next/router';
import useReduxSelector from '@/ui/shared/hooks/useReduxSelector';
import config from '@/Framework/config';
import PageSeo from '@/finsight/ui/common/components/Seo';
import ScrollBodyBackground from '@/ui/shared/components/Layout/ScrollBodyBackground';
import MissingSomethingModalForm from '@/finsight/ui/common/components/MissingSomethingModalForm';
import Footer from '@/finsight/ui/common/components/Footer';
import ScrollToTop from '@/ui/shared/components/Layout/ScrollToTop';
import ContentWrp from '@/ui/shared/components/Layout/ContentWrp';
import PageNotFoundChecker from '@/finsight/ui/common/components/PageNotFoundChecker';
import URL from '@/finsight/Router/urlConstants';
import BreadcrumbsContextProvider from '@/finsight/ui/common/components/header/Breadcrumbs/BreadcrumbsContext';
import StaticPagesContextProvider from '@/finsight/ui/components/static/StaticTemplate/StaticPagesContext';
import HeaderMenuContextProvider from '@/finsight/ui/common/components/header/Header/HeaderMenuContext';
import HeaderDataActiveMenuContextProvider
  from '@/finsight/ui/common/components/header/Header/HeaderMenuActiveDataContext';
import HeaderLayout from '@/finsight/ui/common/components/header/HeaderLayout';
import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';
import { CONTENT_WRAPPER_ID } from './constants';

import styles from './Layout.scss';
import stickyFooterStyles from '@/ui/shared/components/Landing/StickyFooter/stickyFooter.scss';

import IBreadcrumb from '@/finsight/domain/vo/Breadcrumbs';

interface IProps {
  children: React.ReactNode,
  breadcrumbs?: IBreadcrumb[] | { hasError: boolean },
}

const stateSelector = (state) => ({
  metaTags: state.metaTags,
});

const Layout: FC<IProps> = ({ children, breadcrumbs }) => {
  const [isOnStaticPage, setIsOnStaticPage] = useState(false);
  const [isWithScrollToTop, setIsWithScrollToTop] = useState(true);
  const { pathname, asPath } = useRouter();
  const { metaTags } = useReduxSelector(stateSelector);

  useEffect(() => {
    const isStaticPage = URL.STATIC_PAGES_LIST.indexOf(pathname) !== -1;
    if (isStaticPage) {
      setIsOnStaticPage(true);
      return;
    }
    setIsOnStaticPage(false);
  }, [pathname]);

  useEffect(() => {
    /*
     * We can use regexp here, but this solution is a bit more readable
     * another solution below - a bit flexible
     */
    const isPageExist = URL.PAGES_WITHOUT_SCROLL_TO_TOP.find((page) => pathname.substr(0, page.length) === page);
    if (isPageExist) {
      setIsWithScrollToTop(false);
      return;
    }
    /*
     * We can use:
     * if (matchPath(pathname, {
     *  path: pagesWithoutScrollToTop
     * })) {
     *   setIsWithScrollToTop(false);
     *   return;
     * }
     * In this case we cant select whole block of pages.
     * E.g. /sponsor and disable scrollToTop for all pages start with /sponsor
     * But we can specify path's templates like /sponsor/:dealId/:id - so it's more flexible.
     */
    setIsWithScrollToTop(true);
  }, [pathname]);

  return (
    <>
      { !isOnStaticPage && (<PageSeo { ...metaTags } />) }
      <Head>
        <link rel="canonical" href={ finsightWebUrl.getUrl(asPath) } />
      </Head>
      { isWithScrollToTop && (<ScrollToTop />) }
      <ScrollBodyBackground tenant={ config.tenant.finsight.code } />
      <MissingSomethingModalForm />
      <ContentWrp className={ cn(styles.contentWrpOverflowFix) }>
        <StaticPagesContextProvider>
          <BreadcrumbsContextProvider breadcrumbs={ breadcrumbs }>
            <div className={ cn(
                styles.headerAndContentHolder,
                stickyFooterStyles.pageContainerWithStickyFooter,
                stickyFooterStyles.isSmall,
                {
                  [styles.homePageContainer]: pathname === URL.BASE,
                },
              ) }
            >
              <HeaderMenuContextProvider>
                <HeaderDataActiveMenuContextProvider>
                  <HeaderLayout />
                </HeaderDataActiveMenuContextProvider>
              </HeaderMenuContextProvider>
              <PageNotFoundChecker>
                <div
                  className={ cn(styles.layoutChildrenContainer, {
                    [styles.layoutChildrenContainerHeight]: pathname !== URL.BASE,
                  }) }
                  id={ CONTENT_WRAPPER_ID }
                >
                  { children }
                </div>
              </PageNotFoundChecker>
            </div>
            <Footer />
          </BreadcrumbsContextProvider>
        </StaticPagesContextProvider>
      </ContentWrp>
    </>
  );
};

export default Layout;
