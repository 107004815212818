import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import flattenDeep from 'lodash/flattenDeep';
import memoize from '@/ui/shared/helpers/memoize';
import sortBy from 'lodash/sortBy';
import capitalize from '@/Framework/dataHelpers/string/capitalize';
import { toSlug } from '@/Framework/url/helpers/toSlug';
import keyResolver from '@/ui/shared/helpers/keyResolver';
import URL from '@/finsight/Router/urlConstants';
import {
  INDUSTRIES_COLUMNS_CONFIG,
  SEARCH_RESULT_FIRST_COLUMN_CONFIG,
  SECTORS_COLUMNS_CONFIG,
  SEPARATOR,
} from '@/finsight/ui/common/components/header/Header/constants';

/**
 * @param {Array<Object>} sectors
 */
export function prepareSectorsMenu(sectors) {
  return convertColumnsToRows(distributeDataByColumns(
    prepareData(sectors, URL.SECTOR_SUFFIX, 'subsectorList'),
    SECTORS_COLUMNS_CONFIG,
  ));
}

/**
 * @param {Array<Object>} industries
 */
export function prepareIndustriesMenu(industries) {
  return convertColumnsToRows(distributeDataByColumns(
    prepareData(industries, URL.INDUSTRY_SUFFIX, 'subindustryList'),
    INDUSTRIES_COLUMNS_CONFIG,
  ));
}

/**
 * Prepare Right Side Of Menu Hierarchy
 * Form a menu from Sectors OR Industries array
 * @param {Array<Object>} collection
 * @param {String} suffix (sector/ || industry/)
 * @param {String} childrenFieldName
 * @private
 */
export function prepareData(collection, suffix, childrenFieldName) {
  return sortBy(Object.values(collection).map((element) => {
    const link = `${ toSlug(element.name) }-${ suffix }`;

    return {
      id: element.id,
      name: element.name,
      active: false,
      color: element.color,
      link,
      children: Object.values(element[childrenFieldName]).map((child) => ({
        id: child.id,
        name: child.name,
        active: false,
        link: `${
          toSlug(element.name) }-${
          toSlug(child.name)
        }-${ suffix }`,
      })),
    };
  }), (sector) => sector.name);
}

/**
 * @param {Array<Object>} list
 * @param {Array<Number>} columnsConfig
 */
export const distributeDataByColumns = (list, columnsConfig) => columnsConfig.reduce((result, numberOfItems) => {
  const sliceBeginIndex = flattenDeep(result).length;

  return [...result, list.slice(sliceBeginIndex, sliceBeginIndex + numberOfItems)];
}, []);

/**
 * @param {Array<Object>} columns
 */
export function convertColumnsToRows(columns) {
  return columns.reduce((result, col, i) => {
    if (i === 0 || i % 2 === 0) {
      result.push([col]);
    } else {
      result[result.length - 1].push(col);
    }
    return result;
  }, []);
}

/**
 * @param {String} toolPath
 * @returns {Object}
 */
export function getToolConfigByPath(toolPath) {
  let config = {
    route: toolPath,
    skipProductRegionAppend: false,
  };

  switch (toolPath) {
    case 'bonds':
      config.route = 'bonds';
      config.skipProductRegionAppend = true;
      break;

    case 'ee':
      config.route = 'product/us/abs/ee';
      break;

    case 'pipeline':
      config.route = 'product/us/abs/pipeline';
      break;

    case 'research':
      config.route = 'nrsro/research';
      config.skipProductRegionAppend = true;
      break;
    default:
      break;
  }

  return config;
}

/**
 * @param {Object} products
 * @param {Object} regions
 * @param {Object} tools
 * @param {Object} mapping
 * @return {{defaultRegion: *, mappedData, defaultProduct: *, activeMapping}}
 */
export function handleMapping(products, regions, tools, mapping) {
  let defaultProduct;
  let defaultRegion;
  let currentProduct;
  let currentRegion;
  let results = {};
  let mappedData = {};

  // eslint-disable-next-line no-restricted-syntax
  for (let [productId, productMapping] of Object.entries(mapping)) {
    currentProduct = cloneDeep(find(products, (product) => product.id === productId));
    results[currentProduct.id] = currentProduct;
    results[currentProduct.id].regions = {};

    // Iterate through product->regions
    // eslint-disable-next-line no-restricted-syntax
    for (let [regionId, settingsMap] of Object.entries(productMapping)) {
      currentRegion = cloneDeep(find(regions, (region) => region.id === regionId));

      let currentRegionPrepended = {};

      // prepend every region field with region word to be more recognizable
      // eslint-disable-next-line no-restricted-syntax
      for (let [key, value] of Object.entries(currentRegion)) {
        currentRegionPrepended[`region${ capitalize(key) }`] = value;
      }

      results[currentProduct.id].regions[currentRegion.id] = { ...settingsMap, ...currentRegionPrepended };
      results[currentProduct.id].regions[currentRegion.id].tools = {};

      // filter by sector/industry
      if (settingsMap.sectorBlock === true) {
        results[currentProduct.id].regions[currentRegion.id].filterType = 'sector';
      } else {
        results[currentProduct.id].regions[currentRegion.id].filterType = 'industry';
      }

      results[currentProduct.id].regions[currentRegion.id].tools = cloneDeep(
        tools.filter((tool) => !!settingsMap.tools[tool.id])
          .map((tool) => ({ ...tool, ...getToolConfigByPath(tool.path) })),
      );

      // Merge region mapping with product fields with key "productKey"
      // eslint-disable-next-line no-restricted-syntax
      for (let [key, value] of Object.entries(currentProduct)) {
        // skip circular fields injection
        if (key !== 'regions') {
          results[currentProduct.id].regions[currentRegion.id][`product${ capitalize(key) }`] = value;
        }
      }

      // save default product-region combo
      if (results[currentProduct.id].regions[currentRegion.id].default === true) {
        defaultProduct = currentProduct;
        defaultRegion = currentRegion;
      }

      mappedData[currentProduct.id + SEPARATOR + currentRegion.id] = cloneDeep(
        results[currentProduct.id].regions[currentRegion.id],
      );
    }
  }

  return {
    defaultProduct,
    defaultRegion,
    mappedData,
    activeMapping: mappedData[defaultProduct.id + SEPARATOR + defaultRegion.id],
  };
}

/**
 * @param {Object} res
 * @param {Array} products
 * @param {Array} regions
 * @return {Object}
 */
export const mapSearchResult = (res, products, regions) => {
  let hasData = res.totalCount > 0;
  let hasFirstColumnData = false;

  if (Object.keys(res).length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (let [key, values] of Object.entries(res)) {
      if (values.totalCount > 0) {
        if (SEARCH_RESULT_FIRST_COLUMN_CONFIG.find((item) => item.key === key)) {
          hasFirstColumnData = true;
        }
      }
      if (hasData && hasFirstColumnData) {
        break;
      }
    }
  }

  return {
    ...res,
    sectorAndSubsectors: mapSectionLinks(
      res.sectorAndSubsectors,
      'products=ABS&regions=USOA',
      URL.SECTOR_SUFFIX,
    ),
    industryAndSubindustries: mapSectionLinks(
      res.industryAndSubindustries,
      `products=${ getProductsNames(products) }&regions=${ getRegionsNames(regions) }`,
      'bond-issuance-overview',
    ),
    issuers: mapSectionLinks(
      res.issuers,
      `products=${ getProductsNames(products) }&regions=${ getRegionsNames(regions) }`,
    ),
    parents: mapSectionLinks(
      res.parents,
      `products=${ getProductsNames(products) }&regions=${ getRegionsNames(regions) }`,
    ),
    hasData,
    hasFirstColumnData,
  };
};

/**
 * @param {Object} section
 * @param {String} search
 * @param {String} pathnameSuffix
 * @return {Object}
 */
const mapSectionLinks = (section, search, pathnameSuffix) => {
  if (section.totalCount > 0) {
    section.collection = section.collection.map((item) => {
      if (item.url) {
        item.link = {
          pathname: item.url,
          search,
        };
      } else {
        item.link = {
          pathname: !item.additional.childName
            ? `${ toSlug(item.additional.name) }-${ pathnameSuffix }`
            : `${ toSlug(item.additional.name) }-${ toSlug(item.additional.childName) }-${ pathnameSuffix }`,
          search,
        };
      }

      return item;
    });
  }

  return section;
};

export const getMappedSectors = (sectors) => {
  const mappedSectors = {};
  sectors.forEach((sector) => {
    const sectorName = toSlug(sector.name);
    mappedSectors[sectorName] = {
      sectorId: sector.id,
      metaTitleName: sector.name,
      pageTitleName: sector.name,
      sectorName: sector.name,
    };
    if (sector.subsectorList.length) {
      sector.subsectorList.forEach((subsector) => {
        mappedSectors[`${ sectorName }-${ toSlug(subsector.name) }`] = {
          sectorId: sector.id,
          subsectorId: subsector.id,
          sectorName: sector.name,
          metaTitleName: `${ sector.name } ${ subsector.name }`,
          pageTitleName: `${ sector.name }: ${ subsector.name }`,
          subsectorName: subsector.name,
        };
      });
    }
  });
  return mappedSectors;
};

export const getMappedIndustries = (industries) => {
  const mappedIndustries = {};
  industries.forEach((industry) => {
    const sectorName = toSlug(industry.name);
    mappedIndustries[sectorName] = {
      industryId: industry.id,
      metaTitleName: industry.name,
      pageTitleName: industry.name,
      industryName: industry.name,
    };
    if (industry.subindustryList.length) {
      industry.subindustryList.forEach((subindustry) => {
        mappedIndustries[`${ sectorName }-${ toSlug(subindustry.name) }`] = {
          industryId: industry.id,
          industryName: industry.name,
          subindustryId: subindustry.id,
          metaTitleName: `${ industry.name } ${ subindustry.name }`,
          pageTitleName: `${ industry.name }: ${ subindustry.name }`,
          subindustryName: subindustry.name,
        };
      });
    }
  });
  return mappedIndustries;
};

export const getProductsNames = memoize((products) => getNamesStringFromArray(products));
export const getRegionsNames = memoize((regions) => getNamesStringFromArray(regions));

/**
 * @param {Array} array
 * @return {String}
 */
const getNamesStringFromArray = (array) => array.map((item) => item.abbreviation).join(',');

/**
 * @param {Array} mapping
 * @param {Array} selectedProducts
 * @param {Array} selectedRegions
 * @param {String} moduleName
 * @return {boolean}
 */
export const isModuleEnabled = memoize((mapping, selectedProducts, selectedRegions, moduleName) => {
  const pairs = [];
  // Build array of pairs of selected productUUID-regionUUID combinations
  selectedProducts.forEach((productId) => {
    selectedRegions.forEach((regionId) => {
      pairs.push(productId + SEPARATOR + regionId);
    });
  });

  return pairs.some((currentPair) => mapping[currentPair][moduleName] === true);
}, keyResolver);
