import React, { useMemo, useCallback, useState, useEffect } from 'react';
import Tool from './Tool';
import Profile from './Profile';
import useArrowNavigation from '@/finsight/ui/common/components/header/Header/helpers/useArrowNavigation';
import { getMappedMenuData } from '@/finsight/ui/common/components/header/Header/helpers/mappers';
import { useHeaderMenuContext } from '@/finsight/ui/common/components/header/Header/HeaderMenuContext';
import {
  useDictionariesContext,
} from '@/finsight/application/Dictionaries/DictionariesContext';
import { useHeaderDataActiveMenuContext } from '@/finsight/ui/common/components/header/Header/HeaderMenuActiveDataContext';

import styles from './content.scss';
import headerStyles from '@/finsight/ui/common/components/header/Header/header.scss';

interface IProps {
  isOpen: boolean,
}

const Content = React.memo(({ isOpen = false }: IProps) => {
  const {
    sectorsMenu,
    industriesMenu,
    isHeaderInitialized,
  } = useDictionariesContext();

  const {
    activeProduct,
    activeRegion,
    activeMapping,
    isInitialized,
  } = useHeaderDataActiveMenuContext();

  const [profilesMenu, setProfilesMenu] = useState([]);

  useEffect(() => {
    if (activeMapping.sectorBlock) {
      setProfilesMenu(sectorsMenu);
    } else if (activeMapping.industryBlock) {
      setProfilesMenu(industriesMenu);
    }
  }, [activeMapping]);

  const { menuMatrix, isWithToolbar } = useMemo(() => {
    if (!isInitialized || !isHeaderInitialized) {
      return {};
    }
    return getMappedMenuData(
        activeMapping.tools,
        profilesMenu,
        activeMapping[activeMapping.filterType],
        {
          activeProduct: activeProduct.activeProductAbbreviation,
          activeRegion: activeRegion.activeRegionAbbreviation,
        },
      );
    },
  [
    isInitialized,
    isHeaderInitialized,
    activeMapping,
    profilesMenu,
    activeMapping,
    activeRegion.activeRegionAbbreviation,
    activeProduct.activeProductAbbreviation,
  ]);

  const {
    setIsMobileMenuOpen,
    setIsMarketDataMenuOpen,
  } = useHeaderMenuContext();

  const closeHeaderMenu = (params) => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'market_data_dropdown', {
        ...params,
        region: activeRegion.name,
        product: activeProduct.name,
      });
    }

    setIsMobileMenuOpen(false);
    setIsMarketDataMenuOpen(false);
  };

  const activeItemId = useArrowNavigation(menuMatrix, isOpen, isWithToolbar);

  const renderProfile = useCallback((profile) => (
    <Profile
      key={ profile.id }
      activeItemId={ activeItemId }
      onProfileClick={ closeHeaderMenu }
      { ...profile }
    />
  ), [activeItemId, activeRegion.name, activeProduct.name]);

  const renderSubcolumn = useCallback((subColumn, i) => (
    <div
      key={ i }
      className={ styles.subCol }
    >
      { subColumn.map(renderProfile) }
    </div>
  ), [renderProfile]);

  const renderColumn = useCallback((column, index) => (
    <div
      key={ index }
      className={ styles.col }
    >
      { column.map(renderSubcolumn) }
    </div>
  ), [renderSubcolumn]);

  return (
    <div
      className={ styles.contentContainer }
      data-test="marketDataMenuContent"
    >
      <div className={ styles.row }>
        <div className={ styles.toolsTitle }>
          { !activeMapping.regionName ? '' : `${ activeMapping.regionName } ${ activeMapping.productMarketDataName }` } Tools {
            (activeMapping.regionBeta || activeMapping.productBeta)
            && (<span className={ headerStyles.beta }>(Beta)</span>)
          }
        </div>
      </div>
      <div className={ styles.subRow }>
        <div className={ styles.toolsWrp }>
          { activeMapping.tools && activeMapping.tools.map((item) => (
            <Tool
              isActive={ activeItemId === item.id }
              key={ item.id }
              onToolClick={ closeHeaderMenu }
              { ...item }
            />
          )) }
        </div>
      </div>
      <div className={ styles.row }>
        <div className={ styles.sectorsTitle }>
          { `${ !activeMapping.sectorBlock ? 'Industry' : 'Sector' } Profiles` }
        </div>
      </div>
      <div className={ styles.row }>
        { profilesMenu.map(renderColumn) }
      </div>
    </div>
  );
});

export default Content;
