export const INVENTORIES = 'inventories';
export const INVENTORY = 'inventory';
export const BWICS = 'bwics';
export const BWIC = 'bwic';

class BondType {
  /**
   * @return {String}
   */
  static getDefault() {
    return INVENTORIES;
  }

  /**
   * @return {Boolean}
   */
  static isInventories(data) {
    return INVENTORIES === data;
  }

  /**
   * @return {Boolean}
   */
  static isBwics(data) {
    return BWICS === data;
  }

  /**
   * @param {String} bondType
   * @return {Boolean}
   */
  static isValid(bondType) {
    return [
      INVENTORIES,
      BWICS,
    ].includes(bondType);
  }
}

export default BondType;
