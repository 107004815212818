import { combineReducers } from 'redux';
import { routerReducer } from '@dealroadshow/connected-next-router';
import { HYDRATE } from 'next-redux-wrapper';
import { reducer as formReducer } from 'redux-form';
import deals from '@/finsight/application/actions/deals/reducer';

import researchReducer from '@/finsight/application/actions/research/reducer';
import header from '@/finsight/application/actions/header/reducer';
import bonds from '@/finsight/application/actions/bonds/reducer';
import trace from '@/finsight/application/actions/trace/reducer';
import pageFilters from '@/finsight/application/actions/pageFilters/reducer';
import timeZone from '@/Framework/TimeZone/State/Redux/reducer';
import unsubscribe from '@/finsight/application/actions/unsubscribe/reducer';
import redirectReducer from '@/finsight/application/actions/redirect/reducer';

import filters from '@/ui/shared/modules/Filters/reducer';
import checkboxes from '@/ui/shared/modules/Filters/Checkboxer/reducer';
import modalForm from '@/finsight/application/actions/modalForms/reducer';
import metaTags from '@/finsight/application/actions/metaTags/reducer';

import subscriptions from '@/finsight/application/actions/subscriptions/reducer';
import containerReducer from '@/Framework/DI/containerReducer';

const combinedReducer = combineReducers({
  router: routerReducer,
  container: containerReducer,
  match: (state) => state || null,
  research: researchReducer,
  deals,
  headerData: header,
  pageFilters,
  filters,
  checkboxes,
  modalForm,
  timeZone,
  unsubscribe,
  metaTags,
  redirect: redirectReducer,
  form: formReducer,
  subscriptions,
  // THIS IS BETTER
  finsight: combineReducers({
    bonds,
    trace,
  }),
});

const rootReducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state,
      ...action.payload,
    };
    if (typeof window !== 'undefined' && state?.router) {
      // preserve router value on client side navigation
      nextState.router = state.router;
    }
    return nextState;
  }
    return combinedReducer(state, action);
};

export default rootReducer;
